/*
|--------------------------------------------------------------------------
| textpage List Styles
|--------------------------------------------------------------------------
|
*/

.textpage-heading {

}

.textpage-heading h1 {
  margin: 0;
  padding: 8px 8px;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.42857143;
  background-color: #ff6600;
  border-top: 1px solid #ddd;
}

.textpage-refinement {
  padding: 10px 10px 0px 10px;
}

.textpage-refinement h2 {
  font-size: 24px;
}

.textpage-refinement ul,
.textpage-refinement ol {
  padding-left: 20px;
}

.textpage-refinement ol li ol {
  padding-left: 14px;
}

.textpage-refinement .example {
  padding: 10px 0 0px 20px;
  font-style: italic;
}

.table-textpage {
  font-size: 12px;
  margin-bottom: 0;
}

.textpage-refinement ol li ol {
  padding-left: 14px;
}

.table-textpage > thead > tr > th {
  background: #000032;
  color: #fff;
  white-space: nowrap;
  text-transform: uppercase;

}

.table-textpage > tbody > tr > th {
  background: #5169a3;
  color: #fff;
}

.table-textpage > tbody > tr:nth-of-type(2n) {
  background-color: #e6eefb;
}

.table-textpage > tbody > tr:hover {
  background-color: #f5f5f5;
}

.table-textpage > tbody > tr > td {
  text-align: center;
  vertical-align: middle;
}

.table-textpage > tbody > tr > td.details-cell {
  text-align: left;
}

.table-textpage > tbody > tr > td.ticket-cell {
  white-space: nowrap;
}

.table-textpage > tbody > tr > th > span {
  word-spacing: 5px;
}

.table-textpage > tbody > tr > th > span.pipe {
  color: #999999
}