/*
|--------------------------------------------------------------------------
| Close control
|--------------------------------------------------------------------------
|
*/

.sidenav-close {
    display: block;
    padding: 5px;
    color: #b4bcc8;
    text-decoration: none;
    outline: none;
    font-size: 14px;
    font-weight: 600;
    border-bottom: 1px solid #3d4957;
    background: #0b1422;
}
.sidenav-close span {
    display: none;
}
.sidenav-close:hover {
    color: #b4bcc8;
    text-decoration: none;
    cursor: pointer;
}
.sidenav-close i {
    font-size: 24px;
    margin-right: 7px;
}
.sidenav-close i:before {
   color: #535a64;
}


/*
|--------------------------------------------------------------------------
| Menu
|--------------------------------------------------------------------------
|
*/
.sidenav-menu {
    background: #17202e;
}
.sidenav-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
.sidenav-menu ul ul {
    background: #3d4658;
    border-top: 1px solid #17202e;
}
.sidenav-menu li {
    border-radius: 0;
}
.sidenav-menu nav > ul > li {
    background: transparent;
    margin: 0;
    padding: 0;
}
.sidenav-menu ul a {
    display: block;
    color: #b4bcc8;
    outline: none;
}
.sidenav-menu nav > ul > li > a {
    height: 40px;
    padding: 10px 15px 10px 5px;
    text-decoration: none;
    font-size: 14px;
    font-weight: 600;
}
.sidenav-menu nav > ul > li > a.nav-parent-selected,
.sidenav-menu nav > ul > li > a.collapsed.nav-parent-selected {
    background: #ff6a00;
    color: #fff;
}
.sidenav-menu nav > ul > li > a.nav-parent:before {
    content: "\f107";
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: 16px;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    float: right;
    line-height: 20px;
}
.sidenav-menu nav > ul > li > a.nav-parent.collapsed:before {
    content: "\f105";
}

.sidenav-menu nav ul  ul > li > a {
    padding: 5px 15px 5px 40px;
    background: #3d4658;
    font-weight: 300;
}
.sidenav-menu nav ul  ul > li > a.nav-item-selected {
    background: #3e4b5c;
}

.sidenav-menu a:hover {
    text-decoration: none;
}


/*
|--------------------------------------------------------------------------
| Style for a specific menus
|--------------------------------------------------------------------------
|
*/

/*Sports navigation*/
#nav-sports-cntr {
    display: none;
}
#nav-sports-cntr.sidenav-menu{
    display: block;
}
.nav-sports > ul > li > a {
    background: #3d4658;
}
.nav-sports > ul > li > a.collapsed {
    background: transparent;
}
.nav-sports .fs {
    font-size: 22px;
    margin-right: 4px;
    vertical-align: middle;
    color: #6b788b;
}
.nav-sports .nav-parent-selected .fs {
    color: #fff;
}
.nav-sports .nav-parent > span {
    display: inline-block;
    vertical-align: middle;
}

/*Sub navigations selects*/
.panel-subnav-sports .panel-heading {
    padding: 8px 15px;
}

.panel-subnav-sports .panel-title {
    font-size: 15px;
    font-weight: bold;
    text-align: center;
}

/*Side navigation*/
#nav-side-cntr {
    background: #0b1423;
    display: none;
}
#nav-side-cntr.sidenav-menu {
    display: block;
}
.nav-side {
    background: #0b1423;
}
.nav-side .sidenav-close {
    text-align: right;
    border-bottom: none;
}
.nav-side > ul > li > a {
    padding: 10px 15px;
    border-top: 1px solid #3d4957;
}