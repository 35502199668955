/*
|--------------------------------------------------------------------------
| Font Path
|--------------------------------------------------------------------------
|
*/

@font-face {
    font-family: 'FontSports';
    src: url('/fonts/fontsports.eot?v=1.0.0');
    src: url('/fonts/fontsports.eot?#iefix&v=1.0.0') format('embedded-opentype'),
        url('/fonts/fontsports.woff?v=1.0.0') format('woff'),
        url('/fonts/fontsports.ttf?v=1.0.0') format('truetype'),
        url('/fonts/fontsports.svg?v=1.0.0#fontsportsregular') format('svg');
    font-weight: normal;
    font-style: normal;
}


/*
|--------------------------------------------------------------------------
| Element styles
|--------------------------------------------------------------------------
|
*/

.fs {
    display: inline-block;
    font: normal normal normal 14px/1 FontSports;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


/*
|--------------------------------------------------------------------------
| Sports Icons
|--------------------------------------------------------------------------
|
*/

.fs-misc:before {
    content: "\e91d";
}
.fs-soccer:before {
    content: "\e900";
}
.fs-baseball:before {
    content: "\e901";
}
.fs-american-football:before {
    content: "\e902";
}
.fs-basketball:before {
    content: "\e903";
}
.fs-tennis:before {
    content: "\e904";
}
.fs-cricket:before {
    content: "\e905";
}
.fs-golf:before {
    content: "\e906";
}
.fs-badminton:before {
    content: "\e907";
}
.fs-rugby-league:before {
    content: "\e908";
}
.fs-aussie-rules:before {
    content: "\e909";
}
.fs-hockey:before {
    content: "\e90a";
}
.fs-volleyball:before {
    content: "\e90b";
}
.fs-handball:before {
    content: "\e90c";
}
.fs-boxing:before {
    content: "\e90d";
}
.fs-cycling:before {
    content: "\e90e";
}
.fs-chess:before {
    content: "\e90f";
}
.fs-snooker:before {
    content: "\e911";
}
.fs-snow-boarding:before {
    content: "\e912";
}
.fs-darts:before {
    content: "\e914";
}
.fs-table-tennis:before {
    content: "\e915";
}
.fs-bandy:before {
    content: "\e916";
}
.fs-curling:before {
    content: "\e918";
}
.fs-politics:before {
    content: "\e919";
}
.fs-e-sports:before {
    content: "\e91b";
}
.fs-floorball:before {
    content: "\e91d";
}
.fs-mma:before {
    content: "\e90d";
}
.fs-beach-volleyball:before {
    content: "\e90b";
}

