/*
|--------------------------------------------------------------------------
| Game Details Interaction Stylesheet
|--------------------------------------------------------------------------
|
*/

.filter-ml .game-sp,
.filter-ml .game-tt {
    display: none;
}

.filter-sp .game-ml,
.filter-sp .game-tt {
    display: none;
}

.filter-tt .game-ml,
.filter-tt .game-sp {
    display: none;
}
