/*
|--------------------------------------------------------------------------
| Slip Styles
|--------------------------------------------------------------------------
|
*/

.modal-slip .modal-header {
    background: #293243;
    border: none;
    padding: 12px;
}

.modal-slip .modal-header h4 {
    color: #fff;
    font-weight: bold;
    font-size: 21px;
}

.modal-slip .modal-header .close {
    color: #fff;
    font-size: 30px;
    opacity: 0.6;
    font-weight: normal;
    text-shadow: none;
}

.modal-slip .modal-body {
    padding: 0;
}

.modal-slip .modal-content {
    border: none;
    border-bottom: solid 1px transparent;
    border-radius: 0;
}

.noscroll {
    position: fixed;
}


/* ========================================================================
* Slip
* ======================================================================== */
.slip-container .input-sm {
    height: 34px;
    padding: 6px 10px;
    font-size: 16px;
    line-height: 1.5;
}


/* ========================================================================
* Bet type
* ======================================================================== */
.bettype-header {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    padding: 4px 0;
    color: #FFF;
    background: #1C3D6F;
    border: 0;
    letter-spacing: 1px;
    text-align: center;
}

.bettype-panel-body {
    padding: 0;
    color: inherit;
    background-color: transparent;
}

/*.riskwin-group .col-xs-2 {
    line-height: 30px;
    margin-bottom: 0;
}

.form-group.riskwin-group.row {
    padding-top: 5px;
    padding-bottom: 0;
    margin-bottom: 0;
}*/

.bettype-row {
    margin-right: -15px;
    margin-left: -15px;
    padding-bottom: 10px;
}

.bettype-body {
    padding: 5px;
    text-align: center;
    border-bottom: #CECFD2 solid 4px;
}
.bettype-body .bettype-body-sizer {
    padding: 5px 15px;
}

.bettype-option {
    margin: 0;
    padding: 0;
}

.bettype-option.radio {
    padding-left: 10px;
    margin-top: 0;
    min-height: 30px;
    text-align: left;
}
.bettype-body.block-original {
    padding: 0 12px;
}
.bettype-body.block-original .bettype-option .bettype {
    background-color: #69ACEF;
}

.bettype-body.block-white .bettype {
    background-color: #fff;
    color: #337ab7;
}

.bettype-body.block-white .bettype-option {
    margin: 0;
    text-align: left;
}

.bettype-body.block-white .bettype-option.radio .bettype {

}


.bettype-body.block-blue .bettype {
    background-color: #69acef;
    color: #fff;
    border-radius: 4px;
}

.bettype-body.block-yeallow .bettype-option .bettype {
    background: #ffffe0;
}

.bettype-body.block-grey {
    background: #eee;
}
.bettype-body.block-grey .bettype {
    background-color: #e1e1e1;
    color: #333;
}
.btn-option2 {
    background-color: #69ACEF;
    color: #fff;
}

.bettype-title {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    text-transform: uppercase;
    color: #0B1629;
    font-size: 14px;
    padding: 8px 20px;
}

.hidden {
    visibility: hidden;
}

#bettype-show {
    display: block;
}

.slip #slip-amount
{
    width: 100px;
}

#slip-cntr > .alert {
    margin: 10px;
    text-align: center;
}

#slip-cntr > .alert:only-child {
    margin: 40px 10px;
}

.slip-wager .panel-group {
    margin: 0;
}

.slip-wager label {
    font-weight: normal;
    margin: 0;
}

.slip-wager .alert {
    border-radius: 0;
}

.slip-empty {
    text-align: center;
    margin: 40px 20px;
}


.slip-pick {
    border-top: 3px solid #0b1422;
}

.slip-pick:last-child {
    border-bottom: 3px solid #0b1422;
}

.slip-pick div.payout-info{
    text-align: right;
    color: #b5b2b5;
}

.slip-pick div.amount-info{
    text-align: right;
    color: #b5b2b5;
}

.panel-pick {
    box-shadow: none;
    margin: 0;
    border-radius: 0;
    border: none;
}

.panel-pick > .panel-heading {
    background: #e1e1e1;
    border: none;
    border-radius: 0;
    overflow: hidden;
    padding: 10px;
}

.panel-pick > .panel-heading h3 {
    display: inline-block;
    font-size: 16px;
    line-height: 1;
    color: #0b1422;
    font-weight: bold;
    vertical-align: middle;
    max-width: 90%;
}

.panel-pick > .panel-body {
    padding: 10px;
}

.panel-pick .pitchers-row label {
    margin: 1px 0 5px 10px;
}

.panel-pick > .panel-body p {
    margin-bottom: 4px;
}

.panel-pick > .panel-body .pick-price {
    padding: 10px 5px;
    font-weight: bold;
    background-color: #293243;
    color: #fff;
    text-align: center;
    margin: 0;
    line-height: 1;
}

.panel-multibet {
    margin-top: 10px;
}

.panel-multibet .panel-heading {
    padding: 0;
    border-radius: 0;
}

.panel-multibet .panel-heading a {
    padding: 13px 15px;
    display: block;
    color: #0b1422;
    font-size: 15px;
    font-weight: bold;
    text-decoration: none;
}

.panel-multibet .panel-heading a,
.panel-multibet .panel-heading .rrtitle {
    padding: 13px 15px;
    display: block;
    color: #0b1422;
    font-size: 15px;
    font-weight: bold;
    text-decoration: none;
}

.panel-multibet .panel-heading .rrtitle p {
    margin: 0;
}

.panel-multibet .fa-minus {
    float: right;
    margin-right: 5px;
    color: #aaa;
    font-size: 14px;
    line-height: 16px;

}

.panel-multibet .collapsed .fa-minus:before {
    content: "\f067";
}

.form-control {
    font-size: 16px;
}


/* ========================================================================
* Controls
* ======================================================================== */
.rrcomb-control {
    margin: 0;
}

.rrcomb-control label {
    display: block;
}

.slip-inputs {
    padding: 10px 10px 15px 10px;
}

.stake-control {
    line-height: 42px;
}

.stake-control input {
    height: 42px;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    border-radius: 0;
}

.stake-control-multibet {
    text-align: right;
}

.stake-control-multibet input {
    text-align: center;
    border-radius: 0;
}

.riskwin-control {
    text-align: right;
    margin: 0;
    padding: 10px 0 10px 0;
}

.riskwin-control .riskwin-ctrl {
    margin: 4px 0 0 0;
}

.riskwin-control .radio-inline {
    padding-top: 0;
}

.riskwin-control .padding-l-0 {
    padding-top: 0;
}

.riskwin-control .radio-inline + .radio-inline {
    margin-left: 15px;
    margin-right: 2px;
}

.riskwin-control input {
    vertical-align: middle;
    margin-top: 0;
}

.riskwin-control label {
    font-weight: normal;
}

.riskwin-control span {
    vertical-align: middle;
}

.bet-options {
    padding: 0 0 20px 0;
    overflow: hidden;
}

.bet-options input {
    margin: 0 2px 0 0;
    vertical-align: middle;
}

.bet-options span {
    vertical-align: middle;
}

.bet-options label {
    margin-bottom: 15px;
    font-weight: normal;
    display: block;
}

.bet-options label:last-child {
    margin-bottom: 0;
}

.slip-wager .btn-primary {
    color: #fff;
    background-color: #2fa73b;
    padding: 13px 14px;
    font-size: 21px;
    font-weight: bold;
    border-radius: 0;
    width: 100%;
    border: none;
}

.btn-grey {
    padding: 10px 14px;
    color: #0b1422;
    font-size: 15px;
    background-color: #e1e1e1;
    border-radius: 0;
}

.btn-danger {
    border-radius: 0;
}

/* ========================================================================
 * Slip Summary
 * ======================================================================== */

.slip-summary {
    background: #f1f1f1;
}

.slip-summary table {
    margin: 0;
}

.slip-summary th {
    padding-left: 12px;
}

.slip-summary td {
    text-align: right;
    padding-right: 12px;
}

.text-danger {
    text-align: center;
    padding: 5px;
    margin-bottom: 0;
}

/* ========================================================================
 * Buttons
 * ======================================================================== */

.btn-primary {
    background-color: #33577b;
    color: #fff;
}

.btn-danger {
    border-radius: 0;
}

#clear-button {
    border-radius: 0;
    font-weight: bold;
    padding: 6px 12px;
    font-size: 16px;
}

#change-button {
    border-radius: 0;
    font-weight: bold;
    padding: 6px 12px;
    font-size: 16px;
}

#slip-button {
    border-radius: 0;
}

#block-button {

}

#block-button .btn-primary {
    color: #fff;
    background-color: #2fa73b;
    padding: 13px 14px;
    font-size: 21px;
    font-weight: bold;
    border-radius: 0;
    width: 100%;
    border: none;
}


/*Slip opener*/
.slip-opener {
    background-color: #30a73b;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    color: #FFFFFF;
    font-weight: bold;
    text-align: center;
    line-height: 50px;
    font-size: 16px;
    position: fixed;
    z-index: 100;
    bottom: 10px;
    right: 10px;
    -webkit-box-shadow: -2px 2px 8px 0px rgba(0, 0, 0, 0.3);
    box-shadow: -2px 2px 8px 0px rgba(0, 0, 0, 0.3);
    outline: none;
    border: none;
}


.text-left {
    text-align: left;
}

.word-wrap {
    word-wrap: break-word;
}

.padding-b-10 {
    padding-bottom: 10px;
}

.padding-b-5 {
    padding-bottom: 5px;
}

.padding-b-0 {
    padding-bottom: 0;
}

.padding-l-10 {
    padding-left: 10px;
}

.padding-l-5 {
    padding-left: 5px;
}

.padding-l-0 {
    left: 0;
}

.padding-r-10 {
    padding-right: 10px;
}

.padding-r-5 {
    padding-right: 5px;
}

.padding-r-0 {
    padding-right: 0;
}

.padding-t-5 {
    padding-top: 5px;
}

.padding-t-10 {
    padding-top: 10px;
}

.padding-10 {
    padding: 10px;
}

.padding-5 {
    padding: 5px;
}

.padding-0 {
    padding: 0;
}

.margin-0 {
    margin: 0;
}


/* Extra Small Devices make the modal slip have the entire width */
@media only screen and (max-width : 480px) {

    .modal-slip .modal-dialog {
        width: 100%;
        margin: 0;
        padding: 0;
    }

    .modal-slip .modal-content {
        border-radius: 0;
    }

}