/*
|--------------------------------------------------------------------------
| League Games List
|--------------------------------------------------------------------------
|
*/

.panel-group .panel-league {
    border-color: #f0f0f0;
    border-radius: 0;
}

.panel-group .panel-league + .panel-league {
    margin-top: 1px;
}

.panel-league .panel-heading {
    padding: 0;
    border-radius: 0;
}

.panel-league .panel-heading a {
    padding: 8px 15px;
    display: block;
    outline: none;
    font-size: 14px;
}

.panel-league .panel-heading a:hover {
    text-decoration: none;
}

.panel-league .panel-heading .badge {
    float: right;
    margin-top: 2px;
}

.panel-league .league-name {
    vertical-align: middle;
    font-weight: bold;
    max-width: 80%;
    display: inline-block;
}

.panel-league .league-arrow {
    vertical-align: middle;
    font-size: 22px;
    width: 18px;
    display: inline-block;
}

.panel-league .league-arrow:before {
    content: "\f0d7";
}

.panel-league .collapsed .league-arrow:before {
    content: "\f0da";
}

.panel-league .panel-body {
    background: #dee1e5;
    padding: 0;
    font-size: 12px;
}

.list-group-games {
    margin-bottom: 10px;
}

.list-group-games-pt {
    margin-bottom: 0px;
}

/*Games list item*/
.list-group-item-banner {
    
    font-size: 90%;
    background: #ddd;
    padding: 5px 15px;
    
    text-align: center;
    
    border-radius: 0;
    border-bottom: 1px solid #fff;
    
    display: none;
    
}
.list-group-item-banner p {
    margin: 0;
}

/*Games list item*/
.list-group-item-banner-show {
    
    font-size: 90%;
    background: #ddd;
    padding: 5px 15px;
    
    text-align: center;
    
    border-radius: 0;
    border-bottom: 1px solid #fff; 
    display: block;
}
.list-group-item-banner-show p {
    margin: 0;
}

.list-group-game {
    background-color: #dfe1e5;
    border: none;
    margin-bottom: 0;
    border-bottom: 1px solid #babdc4;
}

.list-group-game:last-child {
    border-radius: 0;
}

.panel-league .event-datetime {
    
}

.panel-league .event-team {
    /*font-size: 90%;*/
}

.event-team.fav {
    font-weight: bold;
}

.game-desc {
   text-align: center;
   font-size: 12px;
}
