/*
|--------------------------------------------------------------------------
| FillSlip Styles
|--------------------------------------------------------------------------
|
*/


.settled-picks {
    font-size: 12px;
    border-bottom: 1px #ccc dashed;
    border-top: 1px #ccc dashed;
    background: #ffffe0;
}

.settled-picks h3 {
    font-size: 14px;
    text-align: center;
    margin: 10px;
}

.btn-fillslip + .btn-fillslip {
    width: 100%;
    margin-top: 15px;
}
