body {
    background: #e0e0e0;
    /*padding-bottom: 50px;*/
}

.main-cntr {
    position: relative;
}


/*
|--------------------------------------------------------------------------
| Page header
|--------------------------------------------------------------------------
|
| Styles of the top header, includes the navigation toggles
| and user balance widget
|
*/

.header {
    position: fixed;
    top: 0;
    width: 100%;
    height: auto;
    z-index: 50;
    color: #fff;
    background: #0b1422;
    height: 80px;
}

.header a {
    color: #fff;
}

.header .first-row {
    height: 40px;
}

.sidenav-toggle {
    position: relative;
    padding: 9px 10px;
    background: none;
    border: 1px solid transparent;
    border-radius: 4px;
}

.sidenav-toggle .icon-bar {
    display: block;
    width: 22px;
    height: 2px;
    border-radius: 1px;
    background: #fff;
    clear: both;
}

.sidenav-toggle .icon-bar + .icon-bar {
    margin-top: 6px;
}

.col-logo {
    text-align: center;
    margin-top: 8px;
}

#nav-sports-opener {
    float: left;
}
#nav-opts-opener {
    float: right;
}
#nav-opts-opener .icon-bar {
    width: 4px;
    height: 4px;
    border-radius: 2px;
    margin: 0 9px;
}
#nav-opts-opener .icon-bar + .icon-bar {
    margin-top: 4px;
}

.header .second-row {
    height: 40px;
}

/*
|--------------------------------------------------------------------------
| Content layout
|--------------------------------------------------------------------------
|
*/
.content{
    margin-top: 80px;
    padding-bottom: 5px;
}

/*Allow content aligned to the edge, might be overrided for bigger resolutions*/
.content > .container-fluid {
    padding-left: 0;
    padding-right: 0;
    overflow:  hidden;
}

.page-title {
    margin: 20px 16px 15px;
    font-size: 17px;
    font-weight: bold;
}

.page-bar {
    background-color: #ff6600;
}

/*
|--------------------------------------------------------------------------
| Page footer
|--------------------------------------------------------------------------
|
| Styles of the page footer
|
*/

.footer {
    margin-top: 30px;
    margin-bottom: 10px;
    text-align: center;
    font-size: 12px;
    padding: 0 15px;

    p {
        margin: 0 0 3px;
    }

    a {
        color: #000;
        font-weight: bold;
        &:hover {
            color: #000;
            text-decoration: underline;
        }
    }


}

.footer .phone{
    font-weight: 600;
    color: black;
}