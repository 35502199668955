.balance-cntr {
    padding: 10px 0;
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
}
.balance-cntr a:hover {
    color: #fff;
    text-decoration: none;
}
.balance-cntr a span {
    display: inline-block;
    margin: 0 8px;
}

#full-balance  tbody > tr{
    background-color: white;
}

#full-balance  tbody > tr:nth-child(1){
    padding: 12px 10px;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    background-color: #ff6600 !important;
}

#full-balance > table > tbody > tr:nth-child(2){
    color: #0b1422;
    font-weight: 600;
    background-color: transparent !important;
}

#full-balance  tbody > tr:nth-child(2) > td{
    padding: 30px 0 10px 10px; 
    font-size: 18px;
} 