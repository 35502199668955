.page-spinner {
    position: fixed;
    top: 40%;
    left: 50%;
    margin-left: -30px;
    text-align: center;
    font-size: 10px;
    z-index: 1150;
    display: none;
}

.spinner-open .page-spinner {
    display: block;
}

.page-spinner > div {
    background-color: #ff6300;
    height: 55px;
    width: 6px;
    margin: 0 2px;
    display: inline-block;
    -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
    animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.page-spinner .sp-el2 {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
}

.page-spinner .sp-el3 {
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
}

.page-spinner .sp-el4 {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
}

.page-spinner .sp-el5 {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
    0%, 40%, 100% { -webkit-transform: scaleY(0.4) }  
    20% { -webkit-transform: scaleY(1.0) }
}

@keyframes sk-stretchdelay {
    0%, 40%, 100% { 
        transform: scaleY(0.4);
        -webkit-transform: scaleY(0.4);
    }  20% { 
        transform: scaleY(1.0);
        -webkit-transform: scaleY(1.0);
    }
}

/**
 * Spinner Mask component
 */
.page-spinner-mask {
    position: fixed;
    z-index: 1100;
    top: 0;
    left: 0;
    overflow: hidden;
    width: 0;
    height: 0;
    background-color: #ddd;
    opacity: 0;
    transition: opacity 0.3s, width 0s 0.3s, height 0s 0.3s;
}

.spinner-open .page-spinner-mask {
    width: 100%;
    height: 100%;
    opacity: 0.75;
    transition: opacity 0.3s;
}