.pending-panel {
    
    h2 {
        margin: 0;
        padding: 8px 8px;
        color: #fff;
        font-size: 14px;
        font-weight: 700;
        line-height: 1.42857143;
        background-color: #ff6600;
        border-top: 1px solid #ddd;
    }

    .pending-item {
        background: #fff;
        padding: 0 10px;
    }

    .pending-item-title {
        background: #e1e1e1;
        padding: 10px 0;
        border-top: 2px solid #bbb;
    }

    .pending-item-header {
        padding: 10px 0;
    }

    .pending-item-details.multiple {
        background: #eee;
    }

    .pending-trans-details,
    .pending-item-detail {
        min-height: 40px;
        border-top: 1px solid #fff;
        padding: 10px 0;
    }

    .pending-price {
        display: inline-block;
        padding: 5px 26px;
        font-size: 14px;
        font-weight: bold;
        color: #fff;
        background-color: #babdc4;
        min-width: 100px;
        text-align: center;
    }

    .label {
        font-size: 13px;
        margin: 4px 0;
        display: inline-block;
    }

    .multiple .label {
        background: #eaeaea;
        border: 1px solid;
    }

    .multiple .label-success {
        color: #5cb85c;
    }

    .multiple .label-warning {
        color: #f0ad4e;
    }

    .multiple .label-danger {
    color: #d9534f;
    }

    .multiple .label-default {
    color: #444;
    }
}
