.gototop-wrapper {   
    position: fixed;
    left: 10px;
    bottom: 10px;
    z-index: 100;
    visibility: hidden;
}

.gototop-wrapper.show {
    visibility:visible;
}