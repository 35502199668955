.settings-page {

     h2 {
        margin: 0;
        padding: 8px 8px;
        color: #fff;
        font-size: 14px;
        font-weight: 700;
        line-height: 1.42857143;
        background-color: #ff6600;
        border-top: 1px solid #ddd;
    }
    
}
.setting-body {

    form {
        padding: 15px 5px;
    }
    
    label {
        margin: 0px 0 10px 12px;
    }


    select.form-control {
        height: 40px;
        border: none;
        border-bottom: 2px solid #babdc4;
        font-weight: normal;
        border-radius: 0;
    }

    select.form-control:focus {
        border-color: transparent;
    }

    .btn-primary {
        color: #fff;
        background-color: #2fa73b;
        padding: 10px 16px;
        font-size: 18px;
        line-height: 1.3333333;
        border-radius: 0;
        width: 100%;
        border: none;
    }
}
