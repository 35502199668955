.alert-toast {
    
    width: 240px;
    padding: 5px 15px 5px 10px;
    min-height: 60px;
    margin: 5px 0 0 0;
    
    color: #fff;
    font-size: 13px;
    border: none;
    border-radius: 0;
    
    opacity: 0.95;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=95);
    filter: alpha(opacity=95);
    
}

.alert-toast .close {
    opacity: 0.5;
    right: -10px;
}

.alert-toast.alert-info {
    background-color: #2f96b4;
}
.alert-toast.alert-success {
    background-color: #51a351;
}
.alert-toast.alert-warning {
    background-color: #f89406;
}
.alert-toast.alert-danger {
    background-color: #bd362f;
}

.toast-content {
    overflow: hidden;
}

.toast-icon {
    float: left;
    opacity: 0.8;
    width: 20%;
}

.toast-msg {
    float: left;
    width: 80%;
}

.toast-icon .fa {
    font-size: 30px;
    line-height: 40px;
}
.toast-msg strong {
    display: block;
}
.toast-msg span {
    display: block;
    font-size: 12px;/*whyupper*/
}


@media only screen and (min-width : 480px) {

    .alert-toast {
        width: 340px;
    }
    
    .toast-icon {
        width: 15%;
    }

    .toast-msg {
        width: 85%;
    }

}