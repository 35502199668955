.content-block{
    display: flex;
    justify-content: center;
    align-items: center;
}
.panel-login {
    margin-top: 10%;    
    padding: 30px 20px;
}

.panel-login .panel-body {
    padding: 0;
    max-width: 300px;
}

.panel-login #account{
    text-transform: uppercase;
}

.panel-login .copyright{
    text-align: center;
}