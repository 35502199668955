/*
|--------------------------------------------------------------------------
| Sidenav Interaction Stylesheet
|--------------------------------------------------------------------------
|
*/
.sidenav-open,
.sidenav-open body,
.sidenav-open .sidenav-panel {
  overflow: hidden;
}

.sidenav-panel {
    z-index: 0;
}
.sidenav-panel.has-push-left {
    transform: translateX(100%);
}
.sidenav-panel.has-push-right {
    transform: translateX(-100%);
}

.sidenav-menu {
    position: fixed;
    z-index: 200;
    transition: transform 0.3s;
    -webkit-overflow-scrolling: touch;
    background: #fff;
}

.sidenav-menu-push-left,
.sidenav-menu-push-right {
    top: 0;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
}
.sidenav-menu-push-left {
    left: 0;
    transform: translateX(-100%);
}
.sidenav-menu-push-right {
    right: 0;
    transform: translateX(100%);
}
.sidenav-menu-push-left.active,
.sidenav-menu-push-right.active {
    transform: translateX(0);
}


@media only screen and (min-width: 320px) {

    .sidenav-menu-push-left,
    .sidenav-menu-push-right {
        width: 300px;
    }
    
    .sidenav-panel.has-push-left {
        transform: translateX(300px);
    }
    .sidenav-panel.has-push-right {
        transform: translateX(-300px);
    }

    .sidenav-menu-push-left {
        transform: translateX(-300px);
    }

    .sidenav-menu-push-right {
        transform: translateX(300px);
    }

}


/**
 * Mask component
 */
.o-mask {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    overflow: hidden;
    width: 0;
    height: 0;
    background-color: #000;
    opacity: 0;
    transition: opacity 0.3s, width 0s 0.3s, height 0s 0.3s;
}

.sidenav-open .o-mask {
    width: 100%;
    height: 100%;
    opacity: 0.7;
    transition: opacity 0.3s;
}