/*
|--------------------------------------------------------------------------
| Games Breadcrumbs Bar
|--------------------------------------------------------------------------
|
*/

.page-bar .breadcrumb {
    margin: 0;
    padding-top: 0;
    padding-bottom: 0;
    background: #ff6600;
    border-radius: 0;
    overflow: hidden;
}

.page-bar .breadcrumb > li {
    position: relative;
}

.page-bar .breadcrumb > li > a {
    display: inline-block;
    padding: 9px 15px 9px 20px;
    color: #fff;
    font-size: 13px;
    font-weight: bold;
    line-height: 1;
}

.page-bar .breadcrumb > li:first-child a {
    padding-left: 0;
}

.page-bar .breadcrumb > li::after {
    content: " ";
    display: block;
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-left: 10px solid #ff6600;
    position: absolute;
    top: 50%;
    margin-top: -15px;
    left: 100%;
    z-index: 3;
}
.page-bar .breadcrumb > li::before {
    content: " ";
    display: block;
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-left: 10px solid #b24800;
    position: absolute;
    top: 50%;
    margin-top: -15px;
    margin-left: 1px;
    left: 100%;
    z-index: 3;
}