.gototop-wrapper {
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    opacity: 0;
    background-color: #0b1420;
    color: #eeeeee;
    border-radius: 50px;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
}

.gototop-wrapper.show {
    cursor:pointer;
    opacity: 0.75;
}
.gototop-wrapper i.fa {
    line-height: inherit;
}